<template>
  <el-dialog :model-value="isOpen" @close="$emit('close')" width="45rem">
    <template #header>
      <h3 class="workspaces-edit__header">{{ $t('workspaces.edit.title') }}</h3>
    </template>
    <transition name="blur" appear mode="out-in">
      <div :class="['workspaces-edit', { pending }]">
        <div
          class="workspaces-edit__row"
          v-for="(workspace, i) in editList"
          :key="i"
        >
          <icon-marker :color="workspace.color" />
          <ui-input-underscore
            :placeholder="$t('workspaces.edit.placeholder')"
            @click="onStartEdit(i)"
            @blur="
              editList[i].name.length <= 30 && (editList[i].readonly = true)
            "
            :class="[
              'workspaces-edit__input',
              { error: editList[i].name.length > 30 },
            ]"
            :model-value="editList[i].name"
            @update:model-value="onNameUpdate($event, i)"
            :readonly="workspace.readonly"
            small
            readonly-no-underscore
            ref="workspaces"
          >
            <template #action>
              <div class="workspaces-edit__input-actions">
                <icon-trash
                  color="#EC7975"
                  @click.stop="
                    currentId = workspace.id;
                    isDeleteVisible = true;
                  "
                />
              </div>
            </template>
          </ui-input-underscore>
        </div>
      </div>
    </transition>
    <template #footer>
      <div class="workspaces-edit__footer">
        <ui-button
          :is-loading="pending"
          :is-disabled="isSaveDisabled"
          @click="onSaveChanges"
        >
          {{ $t('workspaces.edit.save') }}
        </ui-button>
      </div>
    </template>
  </el-dialog>
  <modal-delete-workspace
    :is-open="isDeleteVisible"
    :title="$t('workspaces.delete.title')"
    :message="$t('workspaces.delete.message')"
    @close="
      isDeleteVisible = false;
      currentId = null;
    "
    @on-confirm="onDeleteWorkspace"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import ModalDeleteWorkspace from '@/components/modals/Workspaces/ModalDeleteWorkspace.vue';

type Props = {
  isOpen?: boolean;
  list: Workspace[];
};
const props = defineProps<Props>();
defineEmits(['close']);

const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { fetchList, deleteCompany, modify } = workspacesStore;

const workspaces = ref<InstanceType<typeof UiInputUnderscore>[]>([]);
const isDeleteVisible = ref(false);
const pending = ref(false);
const editList = ref<(Workspace & { dirty: boolean; readonly: boolean })[]>([]);
const currentId = ref<number | null>(null);

const isSaveDisabled = computed(
  () =>
    pending.value ||
    !editList.value.some((w) => w.dirty) ||
    editList.value.some((w) => w.name.length > 30),
);

const onStartEdit = (i: number) => {
  editList.value[i].readonly = false;
};

const onDeleteWorkspace = async () => {
  if (!currentId.value) return;
  try {
    isDeleteVisible.value = false;
    pending.value = true;
    await deleteCompany(currentId.value);
    await fetchList();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

const onSaveChanges = async () => {
  try {
    const dirtyEditList = editList.value.filter((w) => w.dirty);
    await Promise.allSettled(dirtyEditList.map((w) => modify(w)));
    await fetchList();
    ElNotification.success({
      message: t('workspaces.edit.saved_msg'),
      offset: 80,
    });
  } catch (e) {
    ElNotification.error({ message: t('some_error'), offset: 80 });
    console.error(e);
  } finally {
    pending.value = false;
  }
};

const onNameUpdate = (name: string, i: number) => {
  editList.value[i].name = name;
  editList.value[i].dirty = name !== props.list[i].name;
};

const setEditList = () =>
  (editList.value = props.list.map((w) => ({
    ...w,
    dirty: false,
    readonly: false,
  })));

watch(() => props.list, setEditList);

watch(
  () => props.isOpen,
  (isOpen) => isOpen && setEditList(),
);
</script>

<style scoped lang="scss">
.workspaces-edit {
  @include flex-column(2rem);
  align-items: center;
  padding: 2.5rem 3rem;
  width: 100%;
  max-height: 30vh;
  overflow: auto;
  transition: all 0.15s ease-in-out;

  &.pending {
    opacity: 0.5;
    pointer-events: none;
  }

  &__header {
    padding-top: 3rem;
    @include f16;
  }

  &__input {
    width: inherit;

    &-actions {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;

      > * {
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        padding: 0.3rem;

        @include gray-bg-hover;
      }
    }
  }

  &__row {
    width: 100%;
    @include flex-row(1rem);
    align-items: baseline;
  }

  &__footer {
    padding: 1rem 3rem 3rem;

    & > * {
      margin-left: auto;
    }
  }
}
</style>
