<template>
  <aside
    class="sidebar"
    :style="
      ['myTasks', 'analytics', 'users', 'settings', 'billing'].includes(
        activeCoach.coach,
      ) && coachElementsStyles[activeCoach.coach][1]
    "
  >
    <nav
      v-click-outside="() => isMenuExpanded && (isMenuExpanded = false)"
      class="sidebar__nav"
      :style="
        isMenuExpanded && {
          width: $i18n.locale === 'ru' ? '30rem' : '25rem',
        }
      "
    >
      <img
        src="@images/expand.svg"
        class="sidebar__toggler"
        :class="{ expanded: isMenuExpanded }"
        @click="isMenuExpanded = !isMenuExpanded"
        alt=""
      />
      <el-popover
        placement="right"
        trigger="click"
        popper-class="is-unstyled"
        :offset="-20"
      >
        <template #reference>
          <button class="sidebar__nav-add">
            <img src="@images/plus.svg" class="new-task" alt="" />
            <template v-if="isMenuExpanded">
              {{ $t('menu.add_task') }}
            </template>
          </button>
        </template>
        <LayoutAsideCreateActions />
      </el-popover>
      <div class="sidebar__nav-list">
        <ui-aside-item
          v-for="{ label, to, iconName } in menu"
          :key="to"
          :icon-name="iconName"
          :to="to"
          :label="$t(label)"
          :show-label="isMenuExpanded"
        />
      </div>
      <div class="sidebar__nav-footer">
        <router-link to="/logout">
          <button class="sidebar__logout">
            <el-tooltip
              :content="$t('menu.log_out')"
              placement="right"
              effect="customized"
            >
              <IconLogout />
            </el-tooltip>
            <template v-if="isMenuExpanded">
              {{ $t('menu.log_out') }}
            </template>
          </button>
        </router-link>
      </div>
    </nav>
  </aside>
</template>

<script setup lang="ts">
import UiAsideItem from '@/components/ui/UiAsideItem.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { ref } from 'vue';
import { menu } from '@/consts/menu';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import LayoutAsideCreateActions from '@/components/layout/app/LayoutAsideCreateActions.vue';

const coachesStore = useCoachesStore();
const { activeCoach, coachElementsStyles } = storeToRefs(coachesStore);

const isMenuExpanded = ref(false);
</script>

<style lang="scss" scoped>
.sidebar {
  width: 9.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 100%;
  left: 2rem;
  &-wrap {
    position: relative;
  }

  &__nav {
    @include flex-column;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    padding: 1.6rem 0;
    top: 9rem;
    bottom: 4.8rem;
    background: #fcfcfc;
    box-shadow: 0 1rem 3rem #99999914;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    overflow-x: hidden;

    & > *:nth-child(2) {
      border-top: 1px solid #dde3ec;
    }

    > img {
      align-self: flex-start;
    }

    &-add {
      @include flex-row(1rem);
      align-items: center;
      white-space: nowrap;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 3rem;
      color: #1a2a39;
      padding: 0.8rem 2.6rem 0.8rem;
      transition: all 0.2s linear;
      width: 100%;

      &:hover {
        background-color: #f2f7f7;
      }
    }

    &-list {
      @include flex-column;
      width: 100%;
    }

    &-footer {
      margin-top: auto;
      width: 100%;
    }
  }
  &__logout {
    padding: 0 2.2rem;
    @include flex-row(1rem);
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 3rem;
    color: #1a2a39;
    transition: all 0.2s linear;

    &:hover {
      background-color: #f2f7f7;
    }

    svg {
      width: 4.8rem;
      height: 4.8rem;
      padding: 1.2rem;
      border-radius: 50%;
      background: #f2f7f7;
      flex: 0 0 auto;
      transition: all 0.15s ease-in-out;
      box-shadow: 0 0.4rem 2rem rgba(#ababab40, 0.25);
    }
  }

  &__toggler {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    margin-left: 3.4rem;
    margin-bottom: 1rem;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}
</style>
