<script setup lang="ts">
import UiModal from '@/components/ui/UiModal.vue';
import { useVModel } from '@vueuse/core';
import UiInput from '@/components/control/UiInput.vue';
import CreateNoteModalFooter from '@/components/notes/create/CreateNoteModalFooter.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import { storeToRefs } from 'pinia';
import IconTrash from '@/components/icon/IconTrash.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { ref, watch } from 'vue';
import { Coach } from '@/types/coaches';
import CouchRecord from '@/components/coaches/note/CouchRecord.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import IconSmart from '@/components/icon/IconSmart.vue';

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits(['update:modelValue']);

const isOpen = useVModel(props, 'modelValue', emit);

const createNoteStore = useCreateNoteStore();
const {
  state,
  isLoading,
  audio,
  isPrefilling,
  fieldIsGenerated,
  isManualPlannigDisabled,
} = storeToRefs(createNoteStore);

const isConfirmClosingOpen = ref(false);

const handleClose = () => {
  isConfirmClosingOpen.value = false;
  isOpen.value = false;
  createNoteStore.$reset();
};

const { isCoachesEnabled } = useCouch();

function useCouch() {
  const coachesStore = useCoachesStore();
  const { setCoach } = coachesStore;
  const { activeCoach, isCoachesEnabled } = storeToRefs(coachesStore);

  const cashedCoach = ref<Coach | null>(null);

  watch(
    () => isOpen.value,
    (value) => {
      if (value) {
        cashedCoach.value = activeCoach.value.coach;
        setCoach('createNote');
      } else {
        cashedCoach.value && setCoach(cashedCoach.value);
      }
    },
  );

  return { isCoachesEnabled };
}
</script>

<template>
  <UiModal
    :is-open="isOpen"
    @on-close-click="isConfirmClosingOpen = true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :close-disabled="true"
    :no-scroll="isCoachesEnabled"
    :title="$t('notes.creating.title')"
    class="create-note-modal"
  >
    <template #headerActions>
      <div
        :class="[
          'create-note-modal__smart',
          { disabled: isManualPlannigDisabled },
        ]"
        @click="createNoteStore.generate(state.description)"
      >
        {{ $t('create_modal_task.smart_planning') }}<icon-smart />
      </div>
      <el-tooltip
        :content="$t('clear_all')"
        effect="customized"
        placement="top"
      >
        <button
          class="create-note-modal__reset"
          @click="createNoteStore.resetState"
          :disabled="audio.isRecording"
        >
          <icon-trash />
        </button>
      </el-tooltip>
      <widget-coach />
    </template>
    <template #body>
      <ModalNotification
        :is-open="isConfirmClosingOpen"
        :title="$t('confirm_close.title')"
        :message="$t('confirm_close.description')"
        @on-close="isConfirmClosingOpen = false"
        @on-confirm="handleClose"
      />
      <couch-record />
      <form class="create-note-modal__form">
        <ui-input
          v-model="state.title"
          :is-smart-value="fieldIsGenerated('title')"
          :placeholder="$t('notes.creating.name_placeholder')"
          :disabled="isLoading || isPrefilling"
        />
        <ui-input
          :highlighted="isPrefilling"
          v-model="state.description"
          :placeholder="$t('notes.creating.description_placeholder')"
          type="textarea"
          rows="15"
          :disabled="isLoading"
          style="resize: vertical"
        />
      </form>
    </template>
    <template #footer>
      <CreateNoteModalFooter
        :is-open-modal="isOpen"
        :is-submit-loading="isLoading"
        @click-submit="createNoteStore.create"
      />
    </template>
  </UiModal>
</template>

<style scoped lang="scss">
.create-note-modal {
  &__form {
    @include flex-column(1.6rem);
  }
}
</style>

<style lang="scss">
.create-note-modal {
  height: unset !important;
  min-height: min(628px, 90vh) !important;

  &__reset {
    @include flex-row;
    padding: 1rem;
    @include gray-bg-hover;
    border-radius: 50%;
    cursor: pointer;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    & > svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .modal__body {
    padding: 1.6rem;
  }

  &__form {
    height: 100%;
  }

  .el-dialog__footer {
    padding: 1.6rem !important;
  }
  &__smart {
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1.6rem;
    @include f12;
    border-radius: 0.8rem;
    border: 0.1rem solid #a3c0f9;
    background: #fcfcfc;
    box-shadow: 0 0 5px 0 var(--color-primary);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin-right: 1rem;

    & > svg {
      color: #a3c0f9;
    }

    &:hover {
      border-color: var(--color-primary);
      box-shadow: 0 0 5px 0 var(--color-primary);

      & > svg {
        color: var(--color-primary);
      }
    }

    &.disabled {
      pointer-events: none;
      border-color: #cecede;
      color: #cecede;
      box-shadow: unset;

      & > svg {
        color: #cecede;
      }
    }
  }
}
</style>
