<template>
  <modal-notification
    :is-open="isOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #header>
      <h3 class="workspaces-accept__title purple-text">
        {{ $t('workspaces.accept.title') }}
      </h3>
    </template>
    <template #default>
      {{
        $t('workspaces.accept.message', {
          creator: creatorName,
          company: currentInternalInvitation?.companyName,
        })
      }}
    </template>
    <template #footer>
      <div class="workspaces-accept__btns">
        <ui-button
          plain
          @click="onSetInvitationStatus(false)"
          :is-disabled="pending"
        >
          {{ $t('workspaces.accept.reject') }}
        </ui-button>
        <ui-button @click="onSetInvitationStatus(true)" :is-disabled="pending">
          {{ $t('workspaces.accept.accept') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
</template>

<script setup lang="ts">
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { computed, ref } from 'vue';
import { concatName } from '@/utils/text';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { storeToRefs } from 'pinia';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useWorkspaceStore } from '@/stores/workspaces';

const { t } = useI18n();
const invitationsStore = useInvitationsStore();
const { setInvitationStatus } = invitationsStore;
const { internalInvitations } = storeToRefs(invitationsStore);

const workspacesStore = useWorkspaceStore();

const pending = ref(false);

const isOpen = computed(() => internalInvitations.value.length > 0);

const currentInternalInvitation = computed(
  () => internalInvitations.value[internalInvitations.value.length - 1],
);

const creatorName = computed(() =>
  concatName(
    currentInternalInvitation.value?.nameCreator,
    currentInternalInvitation.value?.secondNameCreator,
  ),
);

const switchToNextInvitation = () => internalInvitations.value.pop();

const onSetInvitationStatus = async (isAccepted: boolean) => {
  try {
    pending.value = true;
    await setInvitationStatus({
      invitationCode: currentInternalInvitation.value.invitationCode,
      result: isAccepted,
    });
    if (isAccepted) {
      ElNotification.success({
        message: t('workspaces.accept.accepted_msg', {
          company: currentInternalInvitation.value.companyName,
        }),
        offset: 80,
      });
    }
    switchToNextInvitation();
    if (!internalInvitations.value.length) await workspacesStore.fetchList();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.workspaces-accept {
  &__title {
    @include f14;
  }

  &__btns {
    margin-top: 2rem;
    @include flex-row(1rem);

    & > * {
      width: 100%;
    }
  }
}
</style>
