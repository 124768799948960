import { RouteRecordRaw } from 'vue-router';
const SettingsView = () => import('@/views/settings/settings.vue');
const SettingsIndex = () => import('@/views/settings/index.vue');

export default {
  path: '/settings',
  component: SettingsIndex,
  meta: {
    layout: 'app',
  },
  children: [{ path: '', name: 'Settings', component: SettingsView }],
} as RouteRecordRaw;
