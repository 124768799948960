import { computed } from 'vue';

export const menu = computed(() => [
  {
    iconName: 'Agenda',
    to: 'agenda',
    label: 'menu.agenda',
  },
  {
    iconName: 'Home',
    to: '/home',
    label: 'menu.home',
  },
  {
    iconName: 'Calendar',
    to: '/calendar',
    label: 'menu.calendar',
  },
  {
    iconName: 'Notes',
    to: '/notes',
    label: 'menu.notes',
  },
  {
    iconName: 'Analytics',
    to: '/analytics',
    label: 'menu.analytics',
  },
  {
    iconName: 'Users',
    to: '/users',
    label: 'menu.users',
  },
  {
    iconName: 'Settings',
    to: '/settings',
    label: 'menu.settings',
  },
  {
    iconName: 'Billing',
    to: '/billing',
    label: 'menu.billing',
  },
]);
