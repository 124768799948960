<template>
  <header class="layout-header">
    <img src="@images/logo.svg" class="layout-header__logo" alt="" />
    <div class="layout-header__right">
      <a href="https://voiset.org/sign-up">
        <UiButton> Try Voiset for free </UiButton>
      </a>
    </div>
    <div class="layout-header__right layout-header__right--mobile">
      <p>Try Voiset for free</p>
      <a href="https://apps.apple.com/us/app/voiset/id6446641085">
        <img
          src="@images/app-markets/apple-btn-small.svg"
          alt="Voiset app for Apple devices"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset"
      >
        <img
          src="@images/app-markets/google-btn-small-mono.svg"
          alt="Voiset app for Android devices"
        />
      </a>
    </div>
  </header>

  <ModalViewTask
    :is-share="true"
    :is-open="modalTaskViewStore.isOpenModal"
    :fullscreen="false"
    @on-close="modalTaskViewStore.clearTask"
  />
  <el-dialog
    :model-value="!modalTaskViewStore.hasSharedAccess"
    modal-class="modal-notification-medium"
  >
    <template #header>
      <p>🙁</p>
      <p>Access to the task is closed or expired.</p>
    </template>
    Request access and work with the task using the link.
    <template #footer>
      <ui-button> Request access </ui-button>
    </template>
  </el-dialog>
  <div class="page">
    <img src="@images/wave-bg.svg" />
    <footer class="layout-footer">
      <div>
        <img src="@images/logo-icon.svg" alt="Voiset logo" />
        <p>Powered by Voiset</p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import UiButton from '@/components/control/UiButton.vue';
import ModalViewTask from '@/components/modals/ModalViewTask/ModalViewTask.vue';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';

const modalTaskViewStore = useModalViewTaskStore();
</script>

<style scoped lang="scss">
.page {
  position: relative;
  height: 100vh;
  overflow: hidden;

  & > img {
    user-select: none;
    width: 100%;
    height: auto;
  }
}
.layout-footer {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  & > div {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    @include f14();
  }
}
.layout-header {
  position: fixed;
  top: 0;
  z-index: 2999;
  width: 100%;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 4rem;

  &__logo {
    @media (max-width: 640px) {
      width: 8.5rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 2rem;

    & > p {
      @include f14;
    }

    @media (max-width: 640px) {
      display: none;
    }

    &--mobile {
      display: none;
      @media (max-width: 640px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (max-width: 640px) {
    padding: 1.6rem;
  }
}
</style>
