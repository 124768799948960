import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { IFile } from '@/types/common';

// interface

export class FilesService extends ApiService {
  add(formData: FormData) {
    return axiosInstance.post<IFile[]>(ENDPOINTS.files.files, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getFileById(id: number) {
    return axiosInstance.get<Blob>(ENDPOINTS.files.files, {
      params: {
        fileId: id,
      },
      responseType: 'blob',
    });
  }

  deleteFileById(id: number) {
    return axiosInstance.delete(ENDPOINTS.files.files, {
      params: {
        fileId: id,
      },
    });
  }
}
