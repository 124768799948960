import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { ActiveCoach, CoachElementsStyles, Coach } from '@/types/coaches';
import { createNumberedArray } from '@/utils/common';
import { ACTIVE_COACH_ELEMENT_STYLES } from '@/consts/coachTexts';

export const useCoachesStore = defineStore(
  'coaches',
  () => {
    const coachesStepsCount: Record<Coach, number> = {
      dashboard: 7,
      myTasks: 1,
      analytics: 1,
      users: 2,
      settings: 1,
      billing: 1,
      createTask: 2,
      viewTask: 2,
      sharedTask: 2,
      createNote: 1,
      note: 1,
      monthAgenda: 0,
    };

    const coaches = Object.keys(coachesStepsCount) as Coach[];
    const passedCoaches = ref<Partial<Record<Coach, boolean>>>({});

    const isCoachesEnabled = ref(false);

    const activeCoach = ref<ActiveCoach>({
      coach: 'dashboard',
      activeStep: 1,
      steps: coachesStepsCount['dashboard'],
      isEnabled: false,
    });

    function disableCoaches() {
      isCoachesEnabled.value = false;
    }

    function enableCoaches() {
      isCoachesEnabled.value = true;
    }
    function disableActiveCoach() {
      activeCoach.value.isEnabled = false;
    }

    function enableActiveCoach() {
      activeCoach.value.isEnabled = true;
    }

    function setCoach(coach: Coach) {
      activeCoach.value = {
        coach: coach,
        activeStep: 1,
        steps: coachesStepsCount[coach],
        isEnabled: !passedCoaches.value[coach],
      };
    }

    function resetCoach(coach?: Coach) {
      coaches.forEach((c) => (passedCoaches.value[c] = false));
      activeCoach.value = {
        ...activeCoach.value,
        ...(coach && { coach }),
        isEnabled: true,
        activeStep: 1,
      };
      enableCoaches();
    }

    function setStep(step: number) {
      if (step < 1) return;
      if (step > activeCoach.value.steps) {
        passedCoaches.value[activeCoach.value.coach] = true;
        return disableActiveCoach();
      }
      activeCoach.value.activeStep = step;
    }

    const coachElementsStyles = computed(() => {
      const coachesWithSteps = Object.entries(coachesStepsCount) as [
        Coach,
        number,
      ][];

      return coachesWithSteps.reduce((stylesMapByCoaches, [coach, length]) => {
        const steps = createNumberedArray(length);
        stylesMapByCoaches[coach] = steps.reduce((stylesMap, value) => {
          stylesMap[value] =
            isCoachesEnabled.value &&
            activeCoach.value.isEnabled &&
            activeCoach.value.coach === coach &&
            value === activeCoach.value.activeStep
              ? ACTIVE_COACH_ELEMENT_STYLES
              : undefined;
          return stylesMap;
        }, {} as CoachElementsStyles);
        return stylesMapByCoaches;
      }, {} as Record<Coach, CoachElementsStyles>);
    });

    watch(
      () => [activeCoach.value.coach, activeCoach.value.isEnabled],
      () => activeCoach.value.steps === 0 && disableActiveCoach(),
      { immediate: true },
    );

    return {
      isCoachesEnabled,
      activeCoach,
      passedCoaches,
      setCoach,
      resetCoach,
      disableActiveCoach,
      enableActiveCoach,
      disableCoaches,
      setStep,
      coachElementsStyles,
    };
  },
  { persist: true },
);
