<template>
  <modal-notification
    width="56rem"
    :is-open="isOpen"
    @on-close="$emit('close')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #header>
      <h3 class="onboard__header">
        <template v-if="isWorkspaceOwner">
          {{ $t('onboard.title') }}
        </template>
        <template v-else>
          {{ $t('onboard.join_title') }}
          <icon-marker :color="currentWorkspace?.color" />
          {{ currentWorkspace?.name }}
        </template>
      </h3>
    </template>
    <template #default>
      <form class="onboard__form" @click.prevent>
        <div class="onboard__row">
          <h4 class="onboard__title">{{ $t('onboard.first_last_name') }}</h4>
          <div class="onboard__name-row">
            <ui-input-underscore
              :label="$t('onboard.name_placeholder')"
              v-model="onboardForm.firstName"
              :pictogram="false"
            />
            <ui-input-underscore
              :label="$t('onboard.last_name_placeholder')"
              v-model="onboardForm.lastName"
              :pictogram="false"
            />
          </div>
        </div>
        <div class="onboard__row" v-if="isWorkspaceOwner">
          <h4 class="onboard__title">{{ $t('onboard.workspace') }}</h4>
          <div class="onboard__row">
            <ui-input-underscore
              :label="$t('onboard.workspace_placeholder')"
              v-model="onboardForm.workspaceName"
              :pictogram="false"
            />
          </div>
        </div>
        <ui-button
          class="onboard__confirm"
          :is-disabled="isConfirmDisabled"
          :is-loading="pending"
          @click="onConfirm"
        >
          {{ $t('onboard.confirm') }}
        </ui-button>
      </form>
    </template>
    <template #footer><div /></template>
  </modal-notification>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import services from '@/api/services';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { Workspace } from '@/types/workspaces';
import { useUserStore } from '@/stores/user';
import { useSettingsStore } from '@/stores/settings/settings';
import IconMarker from '@/components/icon/IconMarker.vue';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';

defineEmits(['close']);

const isOpen = ref(false);
const pending = ref(false);

const onboardForm = ref({
  firstName: '',
  lastName: '',
  workspaceName: '',
});

const isConfirmDisabled = computed(
  () => !(onboardForm.value.firstName || onboardForm.value.lastName),
);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const settingsStore = useSettingsStore();
const usersStore = useSubordinatesStore();

const workspacesStore = useWorkspaceStore();
const { currentWorkspace, isWorkspaceOwner } = storeToRefs(workspacesStore);

const onConfirm = async () => {
  if (!userData.value?.email) return;
  const requests = [];
  try {
    pending.value = true;
    const { workspaceName, ...credentials } = onboardForm.value;
    requests.push(services.auth.changeUserInfo(credentials));
    if (workspaceName) {
      const modifyWorkspaceParams: Partial<Workspace> = {
        name: workspaceName,
        id: currentWorkspace.value?.id,
      };
      requests.push(services.workspaces.modify(modifyWorkspaceParams));
    }
    await Promise.allSettled(requests);
    await Promise.allSettled([
      settingsStore.getUserData(),
      usersStore.fetch(),
      userStore.getUserData(),
      workspaceName && workspacesStore.fetchList(),
    ]);
    isOpen.value = false;
  } catch (e) {
    console.error(e);
  } finally {
    pending.value = false;
  }
};

watch(
  () => userData.value,
  () => {
    if (!userData.value?.email) return;
    isOpen.value = !userData.value?.firstName && !userData.value?.lastName;
  },
  { deep: true, immediate: true },
);
</script>

<style scoped lang="scss">
.onboard {
  &__form {
    @include flex-column(3rem);
    justify-content: center;
    align-items: center;
  }

  &__header {
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include f20;
    font-weight: 500;
  }

  &__title {
    @include f16;
    margin-bottom: 2rem;
  }

  &__row {
    width: 100%;
  }

  &__name {
    &-row {
      @include flex-row(1.2rem);

      & > * {
        width: 100%;
      }
    }
  }

  &__confirm {
    width: 100%;
  }
}
</style>
