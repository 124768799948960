<template>
  <el-dialog
    :model-value="isOpen"
    class="modal"
    :fullscreen="fullscreen"
    @close="emit('onClose')"
    v-bind="$attrs"
  >
    <template #header>
      <div class="modal__title">
        <div class="modal__icon" v-if="$slots.headerIcon">
          <slot name="headerIcon" />
        </div>
        <slot name="headerTitle">{{ title }}</slot>
      </div>
      <div class="modal__actions">
        <slot name="headerActions" v-if="$slots.headerActions" />
        <button
          class="modal__close"
          @click="
            emit('onClose');
            emit('onCloseClick');
          "
          :disabled="!$attrs['close-disabled']"
        >
          <icon-close />
        </button>
      </div>
    </template>

    <template #default>
      <div
        class="modal__body"
        :style="noScroll ? { overflow: 'hidden' } : undefined"
      >
        <slot name="body" />
      </div>
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { watch } from 'vue';

const emit = defineEmits(['onClose', 'onCloseClick']);
import IconClose from '@/components/icon/IconClose.vue';
const props = defineProps<{
  fullscreen?: boolean;
  isOpen?: boolean;
  title: string;
  noScroll?: boolean;
}>();

watch(
  () => props.noScroll,
  () => {
    document.querySelector('.modal__body')?.scrollTo(0, 0);
  },
);
</script>

<style lang="scss">
.modal {
  &__icon {
    margin-right: 1rem;
    display: inline-flex;
  }
  &__minimize {
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 3.2rem;
    &-line {
      display: inline-block;
      border-radius: 0.8rem;
      width: 1.75rem;
      height: 0.2rem;
      background: #1a2a39;
    }
  }
  &__title {
    @include f16;
    display: flex;
    align-items: center;
  }
  &__actions {
    height: 3rem;
    display: flex;
    align-items: center;
  }

  &__close {
    margin-left: 0.8rem;
    display: inline-flex;
  }
  &__body {
    overflow-y: scroll;
    flex-grow: 1;
    background: #fcfcfc;
    padding: 3rem 3rem 1rem;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 4px solid transparent;
      background-color: #e2e2ec;
    }
  }
}
</style>
