<template>
  <div class="colorfilled-text">
    <span class="colorfilled-text__label" :style="styles">
      <icon-badge
        v-if="badge || badgeOnly"
        :color="mappedRoleType[type]?.badge"
      />
      <slot />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import IconBadge from '@/components/icon/IconBadge.vue';

export type RoleType = keyof typeof mappedRoleType;

type ColorfilledStyles = {
  color: string;
  background: string | false;
  'text-transform'?: string;
  'font-size'?: string;
};

const props = defineProps({
  background: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    required: false,
  },
  type: {
    type: String as PropType<RoleType>,
    required: false,
  },
  uppercase: {
    type: Boolean,
    required: false,
  },
  badge: {
    type: Boolean,
    required: false,
  },
  fontSize: {
    type: Number,
    required: false,
  },
  badgeOnly: {
    type: Boolean,
    required: false,
  },
});

const mappedRoleType = {
  URGENT: { background: '#FFDEDC', badge: '#EC7975' },
  LOW: { background: '#E7E7E7', badge: '#676E7B' },
  NORMAL: { background: '#E5F7FF', badge: '#70C9F4' },
  HIGH: { background: '#FFECD4', badge: '#F19F3C' },
  NOTIFICATION: { background: '#778BEE' },
};

const notificationStyles = {
  'border-radius': '2rem',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  'justify-content': 'center',
  'align-items': 'center',
  'white-space': 'nowrap',
  cursor: 'pointer',
  flex: '0 1 auto',
};

const styles = computed<ColorfilledStyles>(() => {
  const color = props.color || '#1A2A39';

  const background =
    !props.badgeOnly &&
    (props.background ||
      (props.type ? mappedRoleType[props.type]?.background : '#52CB50'));

  return {
    color,
    background,
    'text-transform': props.uppercase ? 'uppercase' : '',
    ...(props.fontSize && { 'font-size': props.fontSize + 'px' }),
    ...(props.type === 'NOTIFICATION' && notificationStyles),
  };
});
</script>

<style lang="scss" scoped>
.colorfilled-text {
  display: flex;
  justify-content: center;
  &__label {
    display: flex;
    flex: 1;
    align-items: center;
    column-gap: 1.2rem;

    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;

    padding: 0.3rem 1.2rem;
    border-radius: 0.8rem;
  }
}
</style>
