import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Workspace } from '@/types/workspaces';
import services from '@/api/services';
import { numberToColor } from '@/utils/common';
import { useUserStore } from '@/stores/user';
import {
  subordinatesMappingToView,
  SubordinateViewType,
} from '@/utils/subordinates';

export const useWorkspaceStore = defineStore('workspace', () => {
  const pending = ref(false);
  const list = ref<Workspace[]>([]);
  const users = ref<SubordinateViewType[]>([]);

  const userStore = useUserStore();
  const { userData } = storeToRefs(userStore);

  const currentWorkspace = computed(() =>
    list.value.find((c) => c.id === userData.value?.currentCompany),
  );

  const isWorkspaceOwner = computed(
    () => currentWorkspace.value?.creatorId === userData.value?.id,
  );

  const ownWorkspacesList = computed(() =>
    list.value.filter((w) => w.creatorId === userData.value?.id),
  );

  const guestWorkspacesList = computed(() =>
    list.value.filter((w) => w.creatorId !== userData.value?.id),
  );

  const fetchList = async () => {
    try {
      list.value = [];
      pending.value = true;
      const { data } = await services.workspaces.list();
      list.value = data
        .filter((c) => c.status !== 'DELETED')
        .map((c) => Object.assign(c, { color: numberToColor(c.id) }));
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const create = async (name: string) => {
    return await services.workspaces.create({ name });
  };

  const modify = async (params: Partial<Workspace>) => {
    return await services.workspaces.modify(params);
  };

  const deleteCompany = async (id: number) => {
    try {
      pending.value = true;
      await services.workspaces.delete(id);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const setCurrent = async (companyId: number) => {
    try {
      pending.value = true;
      await services.workspaces.setCurrent({ companyId });
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const addUser = async (params: { userId: number; companyId: number }) => {
    try {
      pending.value = true;
      await services.workspaces.addUser(params);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const removeUser = async (params: { userId: number; companyId: number }) => {
    try {
      pending.value = true;
      await services.workspaces.removeUser(params);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const fetchUsers = async () => {
    try {
      pending.value = true;
      const response = await services.positions.getUsers({
        pageSize: 1000,
      });
      users.value = response.data.content.map(subordinatesMappingToView);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  return {
    list,
    users,
    ownWorkspacesList,
    guestWorkspacesList,
    currentWorkspace,
    isWorkspaceOwner,
    pending,
    fetchList,
    create,
    modify,
    deleteCompany,
    setCurrent,
    addUser,
    removeUser,
    fetchUsers,
  };
});
