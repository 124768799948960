import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { CreateNote, Note } from '@/types/notes';
import { filesTypes } from '@/consts';
import services from '@/api/services';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useNotesListStore } from '@/stores/notes/useNotesList';
import { useRoute } from 'vue-router';
import { useAudioRecord } from '@/hooks/useAudioRecord';
import { useNoteStateGenerator } from '@/hooks/notes/useNoteStateGenerator';

const createDefaultState = (): Pick<Note, 'title' | 'description'> => ({
  title: '',
  description: '',
});

export const useCreateNoteStore = defineStore('create-note', () => {
  const isStarted = ref(false);
  const state = ref<Pick<Note, 'title' | 'description'>>(createDefaultState());
  const audio = reactive(useAudioRecord());
  const {
    generate,
    resetGenerator,
    fieldIsGenerated,
    isGenerated,
    isGenerating,
  } = useNoteStateGenerator({ state });

  const isPrefilling = computed(
    () => !state.value.description || audio.isRecording || !isGenerated.value,
  );

  const isManualPlannigDisabled = computed(() => {
    return (
      isGenerated.value ||
      isGenerating.value ||
      audio.isRecording ||
      !state.value.description.length
    );
  });

  const isStateValid = computed(() => {
    const { title, description } = state.value;
    return description.length > 0 || (title.length > 0 && !isPrefilling.value);
  });
  const { t } = useI18n();
  const createAudioFile = async () => {
    if (!audio.blobData) return null;
    const file = new File([audio.blobData], 'voice.mp3', {
      type: 'audio/mp3',
    });
    const formData = new FormData();
    formData.append('fileType', filesTypes['AUDIO']);
    formData.append('files', file);

    try {
      const audioFiles = await services.files.add(formData);
      return audioFiles.data[0].id;
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
      return null;
    }
  };

  const getNextNumber = async () => {
    const { data } = await services.notes.getAll({
      pageNumber: 0,
      pageSize: 1,
      paged: true,
    });
    return data.totalElements + 1;
  };

  const route = useRoute();
  const notesListStore = useNotesListStore();

  const createNote = async (audioFileId: number | null) => {
    try {
      const payload: CreateNote = {
        ...state.value,
        audioFileId,
        status: 'ACTIVE',
        priorityNumber: await getNextNumber(),
      };
      await services.notes.create(payload);
      if (route.path.startsWith('/notes')) {
        notesListStore.fetchNotes();
      }
      ElNotification.success({
        message: t('notes.create_message'),
        offset: 80,
      });
      isStarted.value = false;
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  };

  const isLoading = ref(false);
  const create = async () => {
    isLoading.value = true;
    const audioFileId = await createAudioFile();
    await createNote(audioFileId);
    isLoading.value = false;
  };

  const start = () => {
    isStarted.value = true;
  };

  const resetState = () => {
    state.value = createDefaultState();
    audio.resetRecord();
    resetGenerator();
    isLoading.value = false;
  };

  const $reset = () => {
    isStarted.value = false;
    state.value = createDefaultState();
    audio.resetRecord();
    resetGenerator();
    isLoading.value = false;
  };

  watch(isStarted, () => {
    if (!isStarted.value) $reset();
  });

  return {
    state,
    isStateValid,
    isPrefilling,
    isStarted,
    isLoading,
    audio,
    isManualPlannigDisabled,

    create,
    start,
    generate,
    fieldIsGenerated,
    $reset,
    resetState,
  };
});
