<script setup lang="ts"></script>

<template>
  <div class="agenda-header__wrapper">
    <router-link
      to="/agenda/daily"
      class="agenda-header__toggle"
      exact-active-class="agenda-header__toggle--active"
      style="display: none"
      >Daily</router-link
    >
    <router-link
      to="/agenda/week"
      class="agenda-header__toggle"
      exact-active-class="agenda-header__toggle--active"
      >Weekly</router-link
    >
    <router-link
      to="/agenda"
      class="agenda-header__toggle"
      exact-active-class="agenda-header__toggle--active"
      >Monthly</router-link
    >
  </div>
</template>

<style scoped lang="scss">
.agenda-header {
  &__wrapper {
    display: flex;
    background: #f2f7f7;
    border-radius: 1rem;
  }
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    padding: 1.2rem 2.7rem;
    border-radius: 1rem;
    color: #bdbdbd;
    @include f16;
    justify-self: end;
    cursor: pointer;
    transition: all 0.3s ease;

    &--active {
      color: #1a2a39;
      background: #fcfcfc;
      box-shadow: 0 6px 20px 0 rgba(181, 181, 181, 0.42);
    }
  }
}
</style>
