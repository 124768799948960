import { RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useTariffStore } from '@/stores/tariffs/tariffs';

export default {
  path: '/logout',
  name: 'Logout',
  redirect: () => {
    const userStore = useUserStore();
    const billingStore = useTariffStore();
    userStore.removeAuthData();
    billingStore.removeUserTariff();
    return { path: '/login' };
  },
  meta: {
    public: true,
  },
} as RouteRecordRaw;
