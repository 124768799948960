import { RouteRecordRaw } from 'vue-router';

const AgendaView = () => import('@/views/agenda.vue');
const AgendaIndex = () => import('@/views/agenda/index.vue');
const AgendaWeek = () => import('@/views/agenda/week.vue');

export default {
  path: '/agenda',
  component: AgendaView,
  redirect: { name: 'AgendaIndex' },
  meta: {
    layout: 'app',
    noAside: true,
    isAgenda: true,
    background: '#fcfcfc',
  },
  children: [
    {
      name: 'AgendaIndex',
      path: '',
      component: AgendaIndex,
    },
    {
      name: 'AgendaWeek',
      path: 'week',
      component: AgendaWeek,
    },
  ],
} as RouteRecordRaw;
