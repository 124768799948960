<script setup lang="ts">
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { storeToRefs } from 'pinia';
import NoteTransformStepPrepare from '@/components/notes/transform/steps/NoteTransformStepPrepare.vue';
import NoteTransformStepWorkspace from '@/components/notes/transform/steps/NoteTransformStepWorkspace.vue';
import NoteTransformStepCalendar from '@/components/notes/transform/steps/NoteTransformStepCalendar.vue';
import NoteTransformStepGenerate from '@/components/notes/transform/steps/NoteTransformStepGenerate.vue';
import NoteTransformStepTask from '@/components/notes/transform/steps/NoteTransformStepTask.vue';

const notesTransformStore = useNotesTransformStore();
const { currentStep, isStarted } = storeToRefs(notesTransformStore);
</script>

<template>
  <Transition name="fade" appear>
    <div v-if="isStarted" class="note-transform-steps">
      <TransitionGroup name="fade-slide">
        <NoteTransformStepPrepare key="1" v-if="currentStep === 'prepare'" />
        <NoteTransformStepWorkspace
          key="2"
          v-else-if="currentStep === 'workspace'"
        />
        <NoteTransformStepCalendar
          key="3"
          v-else-if="currentStep === 'calendar'"
        />
        <NoteTransformStepGenerate
          key="4"
          v-else-if="currentStep === 'generate'"
        />
        <NoteTransformStepTask key="5" v-else-if="currentStep === 'task'" />
      </TransitionGroup>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.note-transform-steps {
  position: fixed;
  padding: 3rem;
  inset: 0;
  z-index: 1010;
  background: #cfcfcf4d;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.fade-slide-move, /* apply transition to moving elements */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.75s ease;
}

.fade-slide-leave-to,
.fade-slide-enter-from {
  opacity: 0;
}

.fade-slide-enter-from {
  translate: 200px;
}

.fade-slide-leave-to {
  translate: -200px;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.fade-slide-leave-active {
  position: absolute;
}
</style>
