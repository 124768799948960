import { RouteRecordRaw } from 'vue-router';
import SignUp from '@/router/routes/sign-up';
import Login from '@/router/routes/login';
import ResetPass from '@/router/routes/reset-pass';
import Welcome from '@/router/routes/welcome';
import Home from '@/router/routes/home';
import Calendar from '@/router/routes/calendar';
import Users from '@/router/routes/users';
import Billing from '@/router/routes/billing';
import ShareLink from '@/router/routes/shareLink';
import Logout from '@/router/routes/logout';
import Settings from '@/router/routes/settings';
import Analytics from '@/router/routes/analytics';
import Trial from '@/router/routes/trial';
import Purchase from '@/router/routes/purchase';
import Notes from '@/router/routes/notes';
import Booking from '@/router/routes/booking';
import Schedule from '@/router/routes/schedule';
import Agenda from '@/router/routes/agenda';

const isProduction = process.env.NODE_ENV === 'production';
const devRoutes: Array<RouteRecordRaw> = [Booking, Schedule];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'Home' },
  },
  Welcome,
  Login,
  SignUp,
  ShareLink,
  Logout,
  ResetPass,
  Home,
  Calendar,
  Users,
  Billing,
  Settings,
  Analytics,
  Notes,
  Trial,
  Purchase,
  Agenda,
  ...(!isProduction ? devRoutes : []),
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'login' },
  },
];

export default routes;
