<template>
  <component
    class="ui-button"
    :class="classes"
    :disabled="isDisabled || isLoading"
    :is="currentComponent"
    :to="to || null"
  >
    <IconSpinner
      v-if="isLoading"
      circle-color="#ffffff"
      color="#ffffff"
      class="ui-button__loading"
    />

    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import IconSpinner from '../icon/IconSpinner.vue';

const props = withDefaults(
  defineProps<{
    color?: 'green' | 'default' | string | boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isBorder?: boolean;
    to?: string | { name: string };
  }>(),
  { color: 'default', isDisabled: false, isLoading: false, isBorder: false },
);

const currentComponent = computed(() => {
  return props.to ? 'router-link' : 'button';
});

const classes = computed(() => ({
  [`ui-button-color--${props.color}`]: true,
  [`ui-button--border`]: props.isBorder,
  [`ui-button--disabled`]: props.isLoading || props.isDisabled,
  [`ui-button--loading`]: props.isLoading,
}));
</script>

<style lang="scss" scoped>
.ui-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
  padding: 0 2.5rem;
  background: var(--color-primary);
  color: #fcfcfc;
  border: none;
  border-radius: 0.8rem;
  overflow: hidden;
  transition: all 0.15s ease-in, color 0.05s ease-in-out;
  box-shadow: 0 0.4rem 1rem 0 #6373c34d;
  font-size: 1.4rem;
  position: relative;

  &[plain]:not([plain='false']) {
    background: transparent;
    border: 1px solid #a3c0f9;
    color: #1a2a39;

    &:hover,
    &:disabled {
      box-shadow: none;
      border-color: #778bee;
    }

    &:disabled {
      background: #efefef;
    }
  }

  &[plain-gray] {
    background: transparent;
    border: 1px solid #cecede;
    color: #1a2a39;
    box-shadow: none;
  }

  &[text] {
    background: transparent;
    color: #1a2a39;
    padding: 0;
    box-shadow: none;
  }

  &:disabled {
    pointer-events: none;
  }

  &-color {
    &--default {
      &.ui-button--border {
        border: 1px solid var(--color-primary);
        background-color: transparent;
        color: #1a2a39;
        &:hover {
          background-color: var(--color-primary);
          color: #ffffff;
        }
      }

      &:hover,
      &:active,
      &:disabled {
        box-shadow: none;
      }

      &.ui-button--disabled {
        background-color: #aab7ed;
      }
    }

    &--green {
      background-color: #c8f48d;
      box-shadow: 0 0.4rem 1.5rem 0 #97c6594d;
      color: #1a2a39;

      &:hover,
      &:active,
      &:disabled {
        box-shadow: none;
      }

      &.ui-button--disabled {
        background-color: #d6eac1;
      }
    }
  }

  &--loading {
    color: transparent;
  }

  &__loading {
    color: #ffffff;
    animation: spin 1.2s linear infinite;
    width: 80%;
    height: 80%;
    position: absolute;
  }
}
</style>
