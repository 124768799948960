<script lang="ts" setup>
import { Component, computed } from 'vue';
import IconCrown from '@/components/icon/IconCrown.vue';
import IconStar from '@/components/icon/IconStar.vue';
import IconLightning from '@/components/icon/IconLightning.vue';
import { ITariffItemResponse } from '@/types/tariffs';

type Title = ITariffItemResponse['title'];

const props = withDefaults(
  defineProps<{
    type?: Title;
    color?: string;
    size?: 'default' | 'small' | 'medium';
  }>(),
  { type: 'basic', color: '#9bc95e', size: 'default' },
);

const plans: {
  [key in Title]: {
    title: key;
    icon?: Component;
  };
} = {
  general: {
    title: 'general',
  },
  basic: {
    title: 'basic',
    icon: IconStar,
  },
  advanced: {
    title: 'advanced',
    icon: IconLightning,
  },
  premium: {
    title: 'premium',
    icon: IconCrown,
  },
};

const currentPlan = computed(() => {
  const currentType = props.type.toLowerCase() as Title;
  return plans[currentType] || plans['basic'];
});
</script>

<template>
  <div class="ui-plan" :class="[size]">
    <component :is="currentPlan.icon" :color="color" class="icon" />
    <div class="title">{{ currentPlan.title }}</div>
  </div>
</template>

<style lang="scss" scoped>
.ui-plan {
  @include flex-row(1rem);
  align-items: center;
  justify-content: center;
  color: var(--color-success);
  font-family: 'Unbounded', sans-serif;
}

.title {
  color: v-bind(color);
  text-transform: capitalize;
}

.default {
  .title {
    @include f20;
    line-height: 2.5rem;
  }

  .icon {
    width: 3rem;
    height: 3rem;
  }
}

.small {
  .title {
    @include f14;
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }
}

.medium {
  .title {
    @include f16;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
