<template>
  <div class="layout__header header">
    <transition name="fade" appear mode="out-in">
      <div
        class="layout-coaches"
        v-if="isCoachesEnabled && activeCoach.isEnabled"
      />
    </transition>
    <ui-burger
      @toggle="isOpen = !isOpen"
      :is-open="isOpen"
      :class="['header__burger', { mobile: isMobile }]"
      :style="isOpen && 'display: flex'"
    />
    <img class="header__logo" src="@images/logo.svg" alt="" />
    <img class="header__logo-mobile" src="@images/logo-icon.svg" alt="" />
    <widget-header-balance
      class="header__widget-balance"
      v-if="!$route.meta.isAgenda"
    />
    <layout-mobile-aside :is-open="isOpen" @close="isOpen = !isOpen">
      <template #top>
        <widget-header-balance v-if="!$route.meta.isAgenda" />
      </template>
    </layout-mobile-aside>
    <market-links large-buttons no-description class="layout__header-links" />
    <div class="layout__header-right">
      <widget-agenda v-if="$route.meta.isAgenda" />
      <template v-else>
        <widget-work-spaces />
        <widget-todo />
      </template>
      <widget-coach />
      <widget-notification />
      <widget-profile />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, Ref, ref, toRef, watchEffect } from 'vue';
import WidgetProfile from '@/components/widget/WidgetProfile.vue';
import WidgetNotification from '@/components/widget/WidgetNotification.vue';
import WidgetHeaderBalance from '@/components/widget/WidgetHeaderBalance.vue';
import UiBurger from '@/components/ui/UiBurger.vue';
import LayoutMobileAside from '@/components/layout/app/LayoutMobileAside.vue';
import MarketLinks from '@/components/market-links/MarketLinks.vue';
import WidgetTodo from '@/components/widget/WidgetTodo.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import WidgetWorkSpaces from '@/components/widget/WidgetWorkSpaces.vue';
import { useRoute } from 'vue-router';
import WidgetAgenda from '@/components/widget/WidgetAgenda.vue';

const windowWidth =
  inject<Ref<number>>('windowWidth') || toRef(window.innerWidth);

const route = useRoute();
const isOpen = ref(false);

const isMobile = computed(
  () => windowWidth.value <= 1150 || route.meta.noAside,
);

const coachesStore = useCoachesStore();
const { activeCoach, isCoachesEnabled } = storeToRefs(coachesStore);

watchEffect(() => {
  isOpen.value =
    isCoachesEnabled.value &&
    activeCoach.value.isEnabled &&
    activeCoach.value.activeStep === 1 &&
    windowWidth.value <= 1100 &&
    ['myTasks', 'analytics', 'users', 'settings', 'billing'].includes(
      activeCoach.value.coach,
    );
});
</script>

<style lang="scss" scoped>
.test {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(0.5rem);
}
.layout {
  &__header {
    padding: 1.6rem 2.4rem;
    height: 8rem;
    background: #fcfcfc;

    &-right {
      @include flex-row(1rem);
      align-items: center;
      margin-left: auto;
    }

    &-links {
      @media (max-width: 1470px) {
        display: none;
      }
    }
  }
}

.header {
  @include flex-row(1rem);
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__logo {
    height: 2.8rem;

    &-mobile {
      display: none;
    }

    @media (max-width: 1300px) {
      display: none;

      &-mobile {
        display: block;
      }
    }
  }

  &__search {
    width: 100%;
    max-width: 32.7rem;
  }

  &__burger {
    display: none;
    z-index: 1000;

    &.mobile {
      display: flex;
    }
  }

  &__widget {
    &-balance {
      @include break-point(1150px) {
        display: none;
      }
    }
  }
}
</style>
