export const createNumberedArray = (length: number) =>
  Array.from({ length }, (v, i) => i + 1);

export const numericSort = <T extends Record<K, T[K]>, K extends keyof T>(
  array: T[],
  prop: K,
  direction: 'ASC' | 'DESC' = 'ASC',
) => {
  const d = direction === 'DESC' ? -1 : 1;
  return array.sort((a, b) => (a[prop] - b[prop]) * d);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const numberToColor = (id?: number) => {
  if (!id) return '#cecede';
  const idTo16 = (id: number) => Math.floor(id).toString(16).padStart(2, '0');

  const red = idTo16(id * 2);
  const green = idTo16(id * 5);
  const blue = idTo16(id * 25);

  return `#${red}${green}${blue}`.slice(0, 7);
};
