import { ValuesOf } from '@/types/utils';

export const taskStatuses = {
  CREATED: 'CREATED',
  APPOINTED: 'APPOINTED',
  ACCEPTED: 'ACCEPTED',
  DONE: 'DONE',
  CONFIRMED: 'CONFIRMED',
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
} as const;

export const completedTasksStatuses: ITaskStatusItem[] = ['DONE', 'CLOSED'];

export const tariffStatuses = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
} as const;

export const invitationStatuses = {
  CONFIRMED: 'CONFIRMED',
  CREATED: 'CREATED',
  EXPIRED: 'EXPIRED',
  SENT: 'SENT',
} as const;

export const taskPriorities = {
  URGENT: 'URGENT',
  HIGH: 'HIGH',
  NORMAL: 'NORMAL',
  LOW: 'LOW',
} as const;

export const taskFastFilters = [
  // 'CREATED_BY_ME',
  // 'ASSIGMENT_TO_ME',
  // 'COMPLETED_TASKS',
  'OVERDUE_TASKS',
  'TO_DO',
];

export const taskAssigmentFilters = ['ASSIGNED_TO_ME', 'CREATED_BY_ME'];

export const filesTypes = {
  ATTACHMENT: 'ATTACHMENT',
  AUDIO: 'AUDIO',
  PHOTO: 'PHOTO',
} as const;
export const filesTypesMapping = {
  application: filesTypes['ATTACHMENT'],
  audio: filesTypes['AUDIO'],
  image: filesTypes['PHOTO'],
} as const;

export type ITariffStatusItem = keyof typeof tariffStatuses;
export type IInvitationStatusItem = keyof typeof invitationStatuses;
export type ITaskStatusItem = keyof typeof taskStatuses;
export type TTaskPriorityItem = keyof typeof taskPriorities;
export type TaskStatus = (keyof typeof taskStatuses)[number];
export type TaskPriority = TTaskPriorityItem[];
export type TaskFastFilter = (typeof taskFastFilters)[number];
export type TaskAssigmentFilter = (typeof taskAssigmentFilters)[number];

export const TITLES_MAP: Record<TaskStatus, string> = {
  CREATED: 'Created',
  APPOINTED: 'Appointed',
  ACCEPTED: 'Accepted',
  DONE: 'Done',
  CONFIRMED: 'Confirmed',
  CLOSED: 'Closed',
  OPEN: 'Open',
};

export const priorityOptions = (
  Object.keys(taskPriorities) as ValuesOf<typeof taskPriorities>[]
).map((item) => ({
  title: item,
  value: item,
}));
