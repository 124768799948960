import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { TariffHistoryFilters } from '@/types/tariff';
import { Pageable } from '@/types/pagination';
import { ITariff } from '@/types/tariffs';
import { PaymentMethod } from '@stripe/stripe-js';
import qs from 'qs';

export class PurchaseService extends ApiService {
  getStripeFormSecret() {
    return axiosInstance.post<{ clientSecret: string }>(
      ENDPOINTS.purchase.getClientSecret,
    );
  }

  getTrialSubscription(paymentId: string) {
    const [productId, priceId] = paymentId.split('-');
    return axiosInstance.post<{ subscriptionId: string }>(
      ENDPOINTS.purchase.getTrialSubscription,
      null,
      {
        params: { productId, priceId },
      },
    );
  }

  getSubscription(paymentId: string) {
    const [productId, priceId] = paymentId.split('-');
    return axiosInstance.post<{ subscriptionId: string }>(
      ENDPOINTS.purchase.getSubscription,
      null,
      {
        params: { productId, priceId },
      },
    );
  }

  cancelSubscription(subscriptionId: string, reason: string) {
    console.log(reason);
    return axiosInstance.post(ENDPOINTS.purchase.cancelSubscription, null, {
      params: { subscriptionId, reason },
    });
  }

  getPaymentCard() {
    return axiosInstance.get<PaymentMethod[]>(
      ENDPOINTS.purchase.getPaymentCard,
    );
  }

  getInvoiceHistory(params: TariffHistoryFilters) {
    return axiosInstance.get<Pageable<ITariff>>(
      ENDPOINTS.purchase.getInvoiceHistory,
      {
        params,
      },
    );
  }

  changeAutoSubscription(purchaseDetailId: number, change: boolean) {
    return axiosInstance.get(ENDPOINTS.purchase.changeAutoSubscription, {
      params: {
        change,
        purchaseDetailId,
      },
    });
  }
}
