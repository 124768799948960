<template>
  <div class="modal-create-footer footer">
    <div class="footer__lang">
      <!--        <div v-if="isSwitchTranslation">-->
      <!--          <ui-dropdown-autocomplete-->
      <!--            v-model="modalTaskCreateData.languageFrom"-->
      <!--            :items="languagesList"-->
      <!--            @select-item="(value) => (modalTaskCreateData.languageFrom = value)"-->
      <!--          />-->
      <!--          <ui-dropdown-autocomplete-->
      <!--            v-model="modalTaskCreateData.languageTo"-->
      <!--            :items="languagesList"-->
      <!--            @select-item="(value) => (modalTaskCreateData.languageTo = value)"-->
      <!--          />-->
      <!--        </div>-->
      <el-popover width="fit-content" trigger="click">
        <lang-recognition-selector />
        <template #reference>
          <div class="footer__lang-reference">
            <span class="footer__lang-current">
              {{ userStore.userData?.locale.name }}
            </span>
            <span class="footer__lang-desc">
              {{ $t('create_modal_task.select_lange') }}
            </span>
          </div>
        </template>
      </el-popover>
    </div>
    <div class="footer__record">
      <ui-audio-recorder
        :styles="coachElementsStyles['createTask'][1]"
        :audio="audio"
        @onStopRecord="onStopRecord"
        @onStartRecord="onStartRecord"
      />
    </div>
    <div class="footer__control">
      <ui-button
        :isDisabled="isCreateBtnDisabled"
        :isLoading="isDisabledSubmit"
        class="footer__control-submit"
        @click="$emit('click-submit')"
      >
        {{ $t('modal_task.create_task') }}
        <icon-check color="#ffffff" />
      </ui-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user';
import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';
import { storeToRefs } from 'pinia';
import { computed, toRefs, watch } from 'vue';
import LangRecognitionSelector from '@/components/modals/CreateTask/ModalCreateTask/LangRecognitionSelector.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import IconCheck from '@/components/icon/IconCheck.vue';
import UiAudioRecorder from '@/components/ui/UiAudioRecorder.vue';
import { sockets } from '@/api/services';

type Props = {
  isDisabledSubmit: boolean;
  isOpenModal: boolean;
};
defineProps<Props>();
defineEmits(['click-submit']);

const coachesStore = useCoachesStore();
const { coachElementsStyles } = storeToRefs(coachesStore);

const userStore = useUserStore();
const modalCreateTaskStore = useModalCreateTaskStore();
const { parseDescription } = modalCreateTaskStore;

const onStartRecord = async () => {
  sockets.speech.emitter
    .subscribe('AddPartialTranscript', (speech: string) => {
      modalCreateTaskStore.audio.runtimeSpeech = speech;
    })
    .subscribe('AddTranscript', (speech: string) => {
      modalCreateTaskStore.audio.runtimeSpeech = '';
      modalCreateTaskStore.modalTaskCreateData.taskContent += speech;
    })
    .subscribe(
      'onClose',
      () => (modalCreateTaskStore.audio.runtimeSpeech = ''),
    );
};

const onStopRecord = async () => {
  sockets.speech.emitter.reset();
  await parseDescription();
};

const isCreateBtnDisabled = computed(() => {
  const { taskPriority, taskContent, executor, name, deadline } =
    modalTaskCreateData.value;

  return (
    !(
      deadline &&
      taskPriority.value &&
      taskContent &&
      taskContent.length <= 10_000 &&
      executor?.id &&
      name &&
      name.length <= 75
    ) || isRecording.value
  );
});

const { modalTaskCreateData, audio } = storeToRefs(modalCreateTaskStore);
const { isRecording, recordFile } = toRefs(audio.value);

watch(recordFile, (val) => (modalTaskCreateData.value.audioFile = val));
</script>

<style lang="scss" scoped>
.footer {
  @include flex-row;
  min-height: 11rem;

  & > * {
    flex: 1;
  }

  &__lang {
    margin: auto;
    @include flex-column(1rem);

    &-reference {
      @include flex-column(1rem);
      align-items: center;
    }

    &-current {
      @include flex-row;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 4rem;
      background: #fff;
      padding: 0.7rem 1.6rem;
      border: 0.2rem solid #b8b8d233;
      cursor: pointer;
    }

    &-current,
    &-desc {
      @include f12;
    }
  }

  &__record {
    border-left: 0.1rem solid #dde3ec;
    border-right: 0.1rem solid #dde3ec;
    position: relative;
    bottom: -1rem;
  }

  &__control {
    @include flex-column(2rem);
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    &-submit {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @include f12;
    }
  }
}
</style>
