import { RouteRecordRaw } from 'vue-router';
const BillingView = () => import('@/views/billing/billing.vue');
const BillingIndex = () => import('@/views/billing/index.vue');

export default {
  path: '/billing',
  component: BillingView,
  meta: {
    layout: 'app',
  },
  children: [{ path: '', name: 'Billing', component: BillingIndex }],
} as RouteRecordRaw;
