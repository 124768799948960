<template>
  <el-dialog
    :model-value="isOpen"
    :class="[
      `modal-view modal`,
      isShare && 'modal__short',
      isShowComments && 'modal__full',
    ]"
    :modal-class="['el-overlay', isShare && 'short'].join(' ')"
    :close-on-click-modal="!isShare"
    :close-on-press-escape="!isShare"
    :fullscreen="fullscreen"
    :modal="!isShare"
    @close="isOpen && emit('onClose')"
  >
    <template #header>
      <!--      <el-skeleton v-if="isTaskLoading" animated style="width: 30%">-->
      <!--        <template #template>-->
      <!--          <el-skeleton-item style="height: 30px; width: 140px" variant="text" />-->
      <!--        </template>-->
      <!--      </el-skeleton>-->
      <div class="modal__title" v-skeleton="isTaskLoading">
        <div class="modal__number">
          <span>№</span>&nbsp;<span>{{ activeTask.itemNumber }}</span>
        </div>
        <div class="modal__status">
          <div class="modal__icon">
            <img
              :src="
                require(`@images/statuses/${
                  activeTask.taskStatus?.toLowerCase() || 'created'
                }.svg`)
              "
              class="modal-view__icon"
              alt=""
            />
          </div>
          <span v-if="!isTaskLoading && activeTask.taskStatus">
            {{ $t(`tasks.filters.values.${activeTask.taskStatus}`) }}
          </span>
        </div>
      </div>
      <div class="modal__actions">
        <el-popover
          v-if="!isShare && !isTaskLoading"
          v-model:visible="isShowShare"
          placement="bottom"
          popper-class="share__popup"
          width="100%"
          trigger="click"
          @hide="toggleShowShare(false)"
          @show="toggleShowShare(true)"
        >
          <widget-share-task @on-close="toggleShowShare(false)" />
          <template #reference>
            <button class="modal-view__btn-share">
              <IconShare />
              {{ $t('view_modal_task.share') }}
            </button>
          </template>
        </el-popover>

        <button class="modal-view__btn-comments" @click="toggleComments">
          <icon-message />
          {{
            $t(
              !isShowComments
                ? 'view_modal_task.comments'
                : 'view_modal_task.hide_comments',
            )
          }}
        </button>
        <widget-coach />
        <button
          v-if="!isShare"
          class="modal__close"
          @click="isOpen && emit('onClose')"
        >
          <icon-close />
        </button>
      </div>
    </template>

    <template #default>
      <coach-share-task v-if="!isShare" />
      <coach-status v-if="!isShare" />
      <coach-about-task v-if="isShare" />
      <coach-files v-if="isShare" />
      <modal-body-view-task :is-share="isShare" />
    </template>
    <template #footer>
      <modal-footer-view-task :is-share="isShare" />
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref, toRef, watch } from 'vue';

import ModalBodyViewTask from '@/components/modals/ModalViewTask/ModalBodyViewTask.vue';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import IconMessage from '@/components/icon/IconMessage.vue';
import IconShare from '@/components/icon/IconShare.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { storeToRefs } from 'pinia';
import { ITaskStatusItem } from '@/consts';
import ModalFooterViewTask from './ModalFooterViewTask.vue';
import { useDebounceFn } from '@vueuse/core';
import WidgetShareTask from '@/components/widget/WidgetShareTask.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import CoachShareTask from '@/components/coaches/viewTask/CoachShareTask.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { Coach } from '@/types/coaches';
import CoachStatus from '@/components/coaches/viewTask/CoachStatus.vue';
import CoachAboutTask from '@/components/coaches/sharedTask/CoachAboutTask.vue';
import CoachFiles from '@/components/coaches/sharedTask/CoachFiles.vue';

type Props = {
  fullscreen?: boolean;
  isOpen?: boolean;
  isShare?: boolean;
  status?: ITaskStatusItem;
};

const props = withDefaults(defineProps<Props>(), { status: 'CREATED' });

const isOpen = toRef(props, 'isOpen');

const modalViewTaskStore = useModalViewTaskStore();

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);
const cashedCoach = ref<Coach | null>(null);

const isShowShare = ref(false);

const toggleShowShare = (value: boolean) => {
  isShowShare.value = value;
};

const toggleComments = () => {
  if (isShowComments.value) {
    modalViewTaskStore.closeComments();
    useDebounceFn(() => {
      modalViewTaskStore.clearComments();
    }, 500)();
  } else {
    modalViewTaskStore.openComments();
    modalViewTaskStore.getComments();
  }
};

const emit = defineEmits(['onClose']);
const { isTaskLoading, activeTask, isShowComments } =
  storeToRefs(modalViewTaskStore);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.coach;
      setCoach(props.isShare ? 'sharedTask' : 'viewTask');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);
</script>

<style lang="scss" scoped>
.modal-view {
  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__form {
    &-group {
      margin-bottom: 2.4rem;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 0 3rem;
    }

    &-col {
      width: 50%;
    }

    &-input {
      cursor: pointer;
    }

    &-autocomplete {
      position: relative;
    }
  }

  &__picker {
    width: 100%;

    &-input {
      width: 100%;
    }
  }

  &__area {
    height: 20rem;
  }

  &__btn-comments,
  &__btn-share {
    display: flex;
    align-items: center;
    @include f14;
    line-height: 1.1;
    min-height: 2.8rem;
    border-radius: 6px;
    transition: all 0.1s linear;
    padding: 0 1rem;
    margin-right: 1rem;

    &:hover {
      background-color: #ecfadb;
    }

    svg {
      margin-right: 0.85rem;
    }
  }

  &__btn-share {
    margin-right: 1.25rem;
  }

  &__priority {
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.2rem;
    max-width: 27.5rem;
    border-radius: 0.8rem;

    &-title {
      margin-right: 1.2rem;
      @include f14;
    }
  }

  &__footer {
    display: flex;
    gap: 0 3.5rem;

    &-col {
      width: calc(50% - 3.5rem);
      flex-grow: 1;
    }
  }

  &__submit {
    width: 100%;
  }
}

.modal {
  height: max-content;

  &__icon {
    margin-right: 1rem;
    display: inline-flex;

    @media (max-width: 640px) {
      margin-right: 0.8rem;
      & > img {
        width: 2rem;
        height: auto;
      }
    }
  }

  &__minimize {
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 3.2rem;

    &-line {
      display: inline-block;
      border-radius: 0.8rem;
      width: 1.75rem;
      height: 0.2rem;
      background: #1a2a39;
    }
  }

  &__title {
    @include f16;
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 640px) {
      @include f14;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    min-width: 10rem;

    @media (max-width: 640px) {
      @include f14;
    }
  }

  &__number {
    span {
      @include f14;
      line-height: 2rem;
      &:first-child {
        color: #bdbdbd;
        font-weight: 500;
      }
    }
  }

  &__actions {
    height: 3rem;
    display: flex;
    align-items: center;
  }

  &__close {
    margin-left: 0.8rem;
    display: inline-flex;
  }

  &__body {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 3rem 3rem;
  }
}
</style>
