import { ref } from 'vue';
import AudioRecorder from 'audio-recorder-polyfill';
window.MediaRecorder = AudioRecorder;

export function useAudioRecord() {
  let audioContext: AudioContext | null;
  let audioSource: MediaStreamAudioSourceNode | null;
  const isRecording = ref(false);

  const blobData = ref<Blob>();
  const runtimeSpeech = ref<string>('');
  const recordFileRuntime = ref<string | null>(null);
  const recordFile = ref<Blob | null>(null);
  const recordTime = ref<number | null>(null);
  const recordError = ref(false);

  let recorder: MediaRecorder | null;

  const onDataAvailable = (e: BlobEvent) => {
    recordFileRuntime.value = URL.createObjectURL(e.data);
    recordFile.value = e.data;
  };

  const startCreateRecordFile = (stream: MediaStream) => {
    recorder = new MediaRecorder(stream);
    recorder.addEventListener('dataavailable', onDataAvailable);
    recorder.start();
  };

  const startRecording = async () => {
    if (isRecording.value) return stopRecording();
    audioContext = new AudioContext({ latencyHint: 'interactive' });

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      audioSource = audioContext.createMediaStreamSource(stream);
      startCreateRecordFile(stream);

      try {
        await audioContext.audioWorklet.addModule(
          'audio/audio-worklet-processor.js',
        );
      } catch (e) {
        console.error('Ошибка загрузки аудио-рабочего скрипта: ', e);
      }

      if (!audioContext) return;
      const workletNode = new AudioWorkletNode(
        audioContext,
        'audio-input-processor',
      );
      workletNode.port.onmessage = (event) => {
        if (!audioContext) return;
        recordTime.value = audioContext.currentTime;
        const audioData = event.data;
        blobData.value = new Blob([audioData], {
          type: 'application/octet-stream',
        });
      };
      audioSource.connect(workletNode);
      workletNode.connect(audioContext.destination);
      isRecording.value = true;
    } catch (e) {
      console.error('Ошибка получения доступа к медиаустройству: ', e);
    }
  };

  const resetRecord = () => {
    audioContext = null;
    audioSource = null;
    recorder = null;
    recordFile.value = null;
    recordTime.value = null;
    recordFileRuntime.value = null;
    runtimeSpeech.value = '';
    setTimeout(stopRecording, 500);
  };

  const stopRecording = () => {
    isRecording.value = false;
    if (audioContext) audioContext.suspend();
    if (audioSource && audioSource.mediaStream) {
      audioSource.mediaStream
        .getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }
    recorder?.stop();
  };

  return {
    stopRecording,
    isRecording,
    startRecording,
    blobData,
    recordFile,
    recordTime,
    recordError,
    resetRecord,
    recordFileRuntime,
    runtimeSpeech,
  };
}
