<template>
  <transition name="blur" appear mode="out-in">
    <div
      :class="['ui-coach-card', tailPosition, { 'double-tail': doubleTail }]"
      v-if="isOpened"
    >
      <icon-close
        class="ui-coach-card__close"
        @click.stop="isCoachesEnabled = !isCoachesEnabled"
      />
      <h3 class="ui-coach-card__title">{{ title }}</h3>
      <div class="ui-coach-card__content">
        <slot name="content">{{ content }}</slot>
      </div>
      <div class="ui-coach-card__bar" v-if="!compactMode">
        <div class="ui-coach-card__progress" :style="progressWidthStyle" />
      </div>
      <div class="ui-coach-card__steps" v-if="!compactMode">
        {{ `${activeCoach.activeStep}/${activeCoach.steps}` }}
      </div>
      <div class="ui-coach-card__footer">
        <slot name="footer">
          <div class="ui-coach-card__buttons">
            <ui-button
              @click="setStep(--activeCoach.activeStep)"
              plain
              v-if="activeCoach.activeStep !== 1"
            >
              {{ $t('coaches.buttons.back') }}
            </ui-button>
            <ui-button @click="setStep(++activeCoach.activeStep)">
              {{
                $t(
                  `coaches.buttons.${
                    activeCoach.activeStep === activeCoach.steps
                      ? 'got-it'
                      : 'next'
                  }`,
                )
              }}
            </ui-button>
          </div>
        </slot>
      </div>
      <div class="ui-coach-card__triangle" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { TranslateResult } from 'vue-i18n';
import { computed, toRefs, watch } from 'vue';
import { ActiveCoach } from '@/types/coaches';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import IconClose from '@/components/icon/IconClose.vue';

type CoachCardProps = {
  cardCoach: Omit<ActiveCoach, 'steps' | 'isEnabled'>;
  title: string | TranslateResult;
  content?: string;
  tailPosition?: 'top' | 'bottom' | 'left' | 'right';
  maxWidth?: string;
  doubleTail?: boolean;
  compactMode?: boolean;
};

const props = withDefaults(defineProps<CoachCardProps>(), {
  maxWidth: '35rem',
  tailPosition: 'left',
  compactMode: false,
});
const { cardCoach, doubleTail } = toRefs(props);

const coachesStore = useCoachesStore();
const { setStep } = coachesStore;
const { isCoachesEnabled, activeCoach } = storeToRefs(coachesStore);

const isOpened = computed(
  () =>
    isCoachesEnabled.value &&
    activeCoach.value.isEnabled &&
    activeCoach.value.coach === cardCoach.value.coach &&
    activeCoach.value.activeStep === cardCoach.value.activeStep,
);

const progressWidthStyle = computed(() => {
  const width = activeCoach.value.activeStep / activeCoach.value.steps;

  return {
    transition: 'width 0.15s ease-in-out',
    width: `${width * 100}%`,
  };
});
</script>

<style scoped lang="scss">
.ui-coach-card {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9991;
  background: #fcfcfc;
  @include flex-column(1rem);
  max-width: v-bind('maxWidth');
  height: fit-content;
  padding: 2rem;
  border-radius: 1.2rem;
  box-shadow: 10px 10px 40px 0 rgba(66, 66, 66, 0.25);
  transition: all 0.15s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    background: url('/src/assets/images/tail.svg') no-repeat;
    filter: drop-shadow(10px 10px 40px rgb(66, 66, 66));
    width: 2rem;
    height: 2.7rem;
    z-index: -1;
  }

  &.double-tail:after {
    background: url('/src/assets/images/double-tail.svg') no-repeat;
    height: 21.5rem;
    width: 21rem;
  }

  &.top {
    &:after {
      top: -2.2rem;
      left: calc(50% - 2rem);
      transform: rotate(90deg);
    }

    &.double-tail:after {
      left: 21%;
    }
  }

  &.bottom {
    &:after {
      bottom: -2.2rem;
      left: calc(50% - 2rem);
      transform: rotate(-90deg);
    }

    &.double-tail:after {
      left: 21%;
    }
  }

  &.left {
    &:after {
      left: -1.9rem;
      top: calc(50% - 2rem);
    }

    &.double-tail:after {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.right {
    &:after {
      right: -1.9rem;
      top: calc(50% - 2rem);
      transform: rotate(180deg);
    }

    &.double-tail:after {
      top: 15%;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    padding: 0.5rem;
    width: 3.4rem;
    height: 3.4rem;
    top: 1rem;
    right: 2rem;
  }

  &__title {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 137%;
    text-align: left;
    color: var(--color-primary);
  }

  &__content {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 157%;
    text-align: left;
    color: #1a2a39;
  }

  &__bar {
    border-radius: 6rem;
    width: 100%;
    height: 0.6rem;
    background: #cbdbf8;
  }

  &__progress {
    border-radius: 6rem;
    height: 100%;
    background: var(--color-primary);
  }

  &__steps {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 157%;
    text-align: left;
    color: #bdbdbd;
  }

  &__footer {
    width: 100%;
  }

  &__footer {
    @include flex-row;
    justify-content: flex-end;
  }

  &__buttons {
    @include flex-row(1rem);

    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 150%;
    color: #1a2a39;

    > * {
      height: 3.4rem;
    }
  }
}
</style>
