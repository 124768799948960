<template>
  <router-link :to="to" v-slot="{ isActive }" custom>
    <router-link
      class="nav-item"
      :to="to"
      :class="{ 'router-link-active': $route.path.includes(to) }"
      @click="$emit('closeSidebar')"
    >
      <component
        class="nav-item__icon"
        :is-active="isActive || $route.path.includes(to)"
        :is="icon"
      />
      <span class="nav-item__text" v-if="showLabel">{{ label }}</span>
    </router-link>
  </router-link>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  iconName: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  showLabel: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const icon = defineAsyncComponent(
  () => import('@/components/icon/nav/Icon' + props.iconName),
);
</script>

<style lang="scss" scoped>
.nav {
  &-item {
    @include flex-row(1rem);
    align-items: center;
    width: 100%;
    padding: 0.8rem 2.6rem;
    transition: all 0.2s linear;

    &:hover {
      background-color: #f2f7f7;
    }

    &__icon {
      width: 4rem;
      height: 4rem;
      padding: 0.8rem;
      border-radius: 50%;
      background: #f2f7f7;
      flex: 0 0 auto;
      transition: all 0.15s ease-in-out;
      box-shadow: 0 0.4rem 2rem rgba(#ababab40, 0.25);
    }

    &.router-link-active {
      & .nav-item__icon {
        background: var(--color-primary);
      }

      & .nav-item__text {
        color: var(--color-primary);
      }
    }

    &__text {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
