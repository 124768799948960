<template>
  <div class="user-change-lang">
    <div class="user-change-lang__title" @click="emit('onClose')">
      <icon-chevron direction="left" />
      {{ $t('userDropDownMenu.lang') }}
    </div>
    <div class="user-change-lang__lang-list">
      <div
        :class="[
          'user-change-lang__lang-item',
          { current: settingsStore.lang === lang?.abbreviation },
        ]"
        v-for="lang in availableLangList"
        :key="lang.id"
        @click="
          setLang(lang);
          emit('onMenuClose');
        "
      >
        {{ lang.name }}
        <el-radio
          size="small"
          v-if="settingsStore.lang === lang?.abbreviation"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { LangUI } from '@/types/lang';
import IconChevron from '@/components/icon/IconChevron.vue';

const settingsStore = useSettingsStore();

const emit = defineEmits(['onClose', 'onMenuClose']);

const searchLang = ref('');

const { localeUiList } = storeToRefs(settingsStore);

const availableLangList = computed(() => {
  return localeUiList.value.filter(
    (item: LangUI) =>
      item.name.toLowerCase().includes(searchLang.value.toLowerCase()) &&
      ['RU', 'EN'].includes(item.abbreviation),
  );
});

const setLang = async (item: LangUI) => {
  await settingsStore.saveUiLocale(item.abbreviation);
  await settingsStore.getUserData();
  localStorage.lang = item.abbreviation.toLowerCase();
};

onMounted(() => {
  if (!settingsStore.lang) {
    settingsStore.getUserData();
  }
});
</script>
<style scoped lang="scss">
.user-change-lang {
  &__title {
    @include flex-row(0.6rem);
    @include f14;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &__lang-list {
    @include flex-column;
  }

  &__lang-item {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    width: 100%;
    border-radius: 0.8rem;
    padding: 1rem 0.8rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:hover,
    &.current {
      background-color: #f2f7f7;
      color: var(--color-primary);
    }
  }

  &__input {
    margin-bottom: 1rem;
  }
}
</style>
