<template>
  <transition name="fade" mode="out-in" appear>
    <component
      :is="selectedComponent"
      @onClose="hideComponent"
      @onMenuClose="
        emit('onClose');
        hideComponent();
      "
      v-if="selectedComponent"
    />
    <div class="user-dropdown" v-else>
      <div class="user-dropdown-header with-border">
        <ui-avatar :avatar-link="userStore.userData?.avatar?.uuidLink" />
        <div class="user-dropdown-top">
          <p class="user-dropdown-name">{{ fullName }}</p>
          <span class="user-dropdown-plan">
            {{ tariffs?.tariff?.title }}
          </span>
        </div>
      </div>
      <div class="user-dropdown-main with-border">
        <div v-for="item in menuList.main" :key="item.id">
          <div
            :class="['user-dropdown-item', { disabled: item.disabled }]"
            @click="handleClick(item)"
          >
            <span> {{ item.title }}</span>
            <icon-chevron
              direction="right"
              v-if="item.type === MenuTypes.COMPONENT"
            />
          </div>
          <div class="user-dropdown-sub-item" v-if="item.subItem">
            <component :is="item.subItem" @openModal="openModal" />
          </div>
        </div>
      </div>
      <div class="user-dropdown-footer">
        <div
          :class="[
            'user-dropdown-item',
            { 'user-dropdown-slot': item.type === MenuTypes.SLOT },
          ]"
          v-for="item in menuList.footer"
          :key="item.id"
          @click="handleClick(item)"
        >
          <span> {{ item.title }} </span>
          <icon-chevron
            direction="right"
            v-if="item.type === MenuTypes.COMPONENT"
          />
          <component :is="item.subItem" v-if="item.type === MenuTypes.SLOT" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { Component, computed, ref } from 'vue';
import UserChangeLang from '@/components/user/UserChangeLang.vue';
import IconChevron from '@/components/icon/IconChevron.vue';

import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import SubscriptionInfo from '@/components/user/SubscriptionInfo.vue';
import SupportSubComponent from '@/components/user/SupportSubComponent.vue';
import MarketLinks from '@/components/market-links/MarketLinks.vue';
import UserPolitics from '@/components/user/UserPolitics.vue';

enum MenuTypes {
  COMPONENT = 'component',
  LINK = 'link',
  SLOT = 'slot',
}

interface MenuItem {
  id: number;
  title: string;
  type: MenuTypes;
  component?: Component;
  to?: string;
  subItem?: Component;
  func?: () => void;
  disabled?: boolean;
}

const userStore = useUserStore();
const billingStore = useTariffStore();

const { tariffs } = storeToRefs(billingStore);

const emit = defineEmits(['onClose', 'openFeedback', 'openContact']);

const { t } = useI18n();

const router = useRouter();

const selectedComponent = ref<Component | null>(null);

const menuList = computed<{ [key: string]: Array<MenuItem> }>(() => {
  return {
    main: [
      {
        id: 1,
        title: t('userDropDownMenu.lang'),
        type: MenuTypes.COMPONENT,
        component: UserChangeLang,
      },
      {
        id: 2,
        title: t('userDropDownMenu.upgrade_plan'),
        type: MenuTypes.LINK,
        to: '/billing',
        subItem: SubscriptionInfo,
      },
      {
        id: 3,
        title: t('userDropDownMenu.settings'),
        type: MenuTypes.LINK,
        to: '/settings',
      },
      {
        id: 4,
        title: t('userDropDownMenu.support'),
        type: MenuTypes.LINK,
        subItem: SupportSubComponent,
        disabled: true,
      },
    ],
    footer: [
      {
        id: 1,
        title: '',
        type: MenuTypes.SLOT,
        subItem: MarketLinks,
      },
      {
        id: 1,
        title: '',
        type: MenuTypes.SLOT,
        subItem: UserPolitics,
      },
      {
        id: 2,
        title: t('userDropDownMenu.log_out'),
        type: MenuTypes.LINK,
        to: '/logout',
      },
    ],
  };
});

const fullName = computed(() => {
  return `${userStore?.userData?.firstName} ${userStore.userData?.lastName}`;
});

const handleClick = (item: MenuItem) => {
  switch (item.type) {
    case MenuTypes.COMPONENT:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedComponent.value = item.component!;
      break;
    case MenuTypes.LINK:
      router.push(item.to as string);
      emit('onClose');
      break;
  }
};

const hideComponent = () => {
  selectedComponent.value = null;
};

const openModal = (modal: string) => {
  switch (modal) {
    case 'contact':
      emit('openContact');
      break;
    case 'feedback':
      emit('openFeedback');
      break;
  }
};
</script>

<style scoped lang="scss">
.user-dropdown {
  &-header {
    @include flex-row(1rem);
    align-items: center;
    padding: 0 0.8rem;
  }

  &-item {
    @include flex-row();
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    border-radius: 0.8rem;
    padding: 1rem 0.8rem;
    transition: all 0.15s ease-in-out;

    &:not(.disabled):hover {
      background-color: #f2f7f7;
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  &-slot {
    justify-content: flex-start;
  }

  &-sub-item {
    padding: 0 0.8rem;
  }

  &-name {
    @include f14;
    color: #1a2a39;
  }

  &-plan {
    @include f12;
    color: #b8b8d2;
  }

  &-avatar {
    @include avatar;

    &--default {
      position: relative;
      background-image: url('@/assets/images/pictogram/contact.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: #dde3ec;
      background-size: 2.7rem;
    }
  }
}

.with-border {
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #dde3ec;
  margin-bottom: 1rem;
}
</style>
