<template>
  <div class="ui-avatar" :style="size && { width: size, height: size }">
    <img
      ref="image"
      :src="
        computedAvatarLink ||
        (isImageLoaded ? require(`@images/default-avatar.svg`) : '')
      "
      alt="avatar"
      class="ui-avatar__image"
      :class="{ 'ui-avatar__image-loading': !isImageLoaded }"
    />
    <transition name="fade" mode="out-in" appear>
      <icon-spinner
        v-if="!isImageLoaded"
        circle-color="#ffffff"
        color="#ffffff"
        class="ui-avatar__loading"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { createAvatarLink } from '@/utils/url';
import { computed, ref, watch } from 'vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';

const props = defineProps<{
  avatarLink?: string | null;
  size?: string;
}>();

const isImageLoaded = ref(false);

const image = ref<HTMLImageElement | null>(null);
watch(
  () => image.value,
  () => {
    if (image.value) {
      image.value.onload = () => (isImageLoaded.value = true);
      image.value.onerror = () => (isImageLoaded.value = true);
    }
  },
);

watch(
  () => props.avatarLink,
  () => props.avatarLink && (isImageLoaded.value = false),
);

const computedAvatarLink = computed(() => createAvatarLink(props.avatarLink));
</script>

<style scoped lang="scss">
.ui-avatar {
  position: relative;
  @include avatar;
  transition: opacity 0.2s ease-in-out;

  &__image {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &-loading {
      opacity: 0;
    }
  }

  &__loading {
    position: absolute;
    inset: 0;
    color: #ffffff;
    animation: spin 1.2s linear infinite;
    height: 100%;
    width: 100%;
  }
}
</style>
